import HeaderWrappedView from "../HeaderWrappedView/HeaderWrappedView"

import './Menu.scss'

function Menu() {
	return (
		<HeaderWrappedView routeName="menu" title="Our Menu" isContentPage>
			<div className="menuWrapper">
				<h2 style={{ marginTop: '0' }}>
					<span className="sectionHeaderBody">
						Sandwiches
					</span>
				</h2>
				<ul>
					<li>Tri-Tip</li>
					<li>BBQ (Beef)</li>
					<li>"The Santa Maria"</li>
				</ul>
				<h2>
					<span className="sectionHeaderBody">
						Menu for 2
					</span>
				</h2>
				<ul>
					<li>BBQ Plate</li>
					<li>Quesadilla</li>
					<li>BBQ Nachos</li>
					<li>Phat Tater</li>
				</ul>
				<h2>
					<span className="sectionHeaderBody">
						Meats per lb
					</span>
				</h2>
				<ul>
					<li>Tri-Tip</li>
					<li>Ribs</li>
					<li>BBQ Birria</li>
					<li>BBQ Chicken</li>
				</ul>
				<h2>
					<span className="sectionHeaderBody">
						Quick Bites
					</span>
				</h2>
				<ul>
					<li>Smoked Sausage</li>
					<li>Chicken Thigh</li>
					<li>BBQ Taco</li>
				</ul>
			</div>
    </HeaderWrappedView>
	)
}

export default Menu