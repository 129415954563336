import HeaderWrappedView from "../HeaderWrappedView/HeaderWrappedView"

import './OurStory.scss'

function OurStory() {
	return (
		<HeaderWrappedView routeName="ourStory" title={'Phat Purps is a family owned and operated barbecue pop-up shop.\u202F Based out of Whittier, CA.'} isContentPage>
			<p>Born and raised in <span className="highlight">Southern California</span>, pitmaster Mando "Purp" Moya always had a passion for cooking and serving people delicious foods. At the age of 13 Mando knew he wanted to cook and bake foods that would bring people together. Shortly after being accepted into <span className="highlight">Le Cordon Bleu</span> school of culinary arts and deciding to major in Baking and Patisserie Arts, Mando was injured in a sporting accident which forced him to drop out due to lack of movement in his leg. Mando continued to work on recipes and BBQ for his close friends and family as his passion never left his side.</p>
			<img src="/purp-1.jpeg" alt="Papa Purp" className="purpImage" />
			<p>In <span className="highlight">2010</span> Mando thought he found his new career, choosing to become a Loss Prevention Manager and train people throughout the United States on how to observe and track people inside retail businesses.</p>
			<p>In <span className="highlight">April 2017</span> Mando was stabbed 13 times with a screwdriver while attempting to apprehend a shoplifter. This stabbing left Mando in a dire situation with puncture wounds to his lungs, the sac around his heart as well as his neck, abdomen, and face. This incident along with several other life altering incidents made Mando reconsider his life and what he had to leave behind for his family. After taking time to recover and be with his children Mando knew he had to return to work to continue to support his family.</p>
			<p>Fast forward to <span className="highlight">2022</span> and several retail positions later, Mando found himself just going through the motions of working, paying bills, and spending weekends with his family when non-work hours allowed it. <span className="highlight">At the urging of some of his friends</span> Mando decided to try and sell some of his food as a way to make extra money.</p>
			<p>In <span className="highlight">June 2022</span> a customer urged Mando to join a possible event which would have other barbecue vendors as well as a barbecue competition. Though a bit apprehensive Mando decided to go through with it and give it a shot. To his surprise Mando won for <span className="highlight">best BBQ sauce</span>, <span className="highlight">best BBQ chicken</span>, and <span className="highlight">best overall BBQ</span>, beating teams of several chefs with just the help of his wife Patty and friend Alex. After this event somebody said congratulations chef which really touched Mando and reminded him this is your passion <span className="highlight">this is what you're meant to do</span>. Thus, Chef Purp was born!</p>
			<p>Chef Purp has decided to fully invest in his love and passion and create delicious food and sauces for his friends family and loyal customers. Selling <span className="highlight">Santa Maria style BBQ</span> chef Purp has brought a unique style and flavor to Southern California that is only known up in Santa Maria, California. Chef Purp is here to bring California style BBQ the attention it deserves.</p>
			<p className="lastEdited">Last edited: November 2022</p>
		</HeaderWrappedView>
	)
}

export default OurStory