import { useState } from "react"

import HeaderWrappedView from "../HeaderWrappedView/HeaderWrappedView"

import './Contact.scss'

// const encode = (data) => {
// 	return Object.keys(data)
// 			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
// 			.join("&");
// }

function Contact() {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [message, setMessage] = useState("")

	// const onSubmit = (e) => {
	// 	fetch("/", {
	// 		method: "POST",
	// 		headers: { "Content-Type": "application/x-www-form-urlencoded" },
	// 		body: encode({
	// 			"form-name": "contact",
	// 			name,
	// 			email,
	// 			phone,
	// 			message
	// 		})
	// 	})
	// 		.then(() => alert("Success!"))
	// 		.catch(error => alert(error));

	// 	e.preventDefault()
	// }

	return (
		<HeaderWrappedView routeName="contact" title="Contact" isContentPage>
      <form className="contactForm" name="contact" method="POST" netlify data-netlify="true" data-netlify-honeypot="bot-field">
				<p className="intro">Looking for someone to do your next event?<br /><br />Send us a quick message and we'll get back with you soon!</p>
				<div className="inputGroup">
					<label>
						Your Name:
						<input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
					</label>
				</div>
				<div className="inputGroup">
					<label>
						Your Email:
						<input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
					</label>
				</div>
				<div className="inputGroup">
					<label>
						Your Phone Number:
						<input type="text" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
					</label>
				</div>
				<div className="inputGroup">
					<label>
						Message:
						<textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
					</label>
				</div>
				<div className="inputGroup">
					<button type="submit">Send</button>
				</div>
			</form>
    </HeaderWrappedView>
	)
}

export default Contact