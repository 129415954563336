import './FeaturedImage.scss'

function FeaturedImage() {
  return (
    <div className="featuredImage">
      <div className="overlay">
        <div className="overlayContainer">
          <div className="titleContent">
            <span className="title">Phat Purp's Smoke Shop 🔥</span><br />
            <span className="subtitle">Serving Santa Maria style BBQ since 2022</span><br />
            <a href="/our-story" className="ourStoryLink">Read our story &gt;</a>
            <a href="/menu" className="menuLink">Check out our menu &gt;</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedImage