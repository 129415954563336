import Header from "../../Components/Header/Header"

import './HeaderWrappedView.scss'

function HeaderWrappedView({ routeName, children, isContentPage, title }) {
	return (
		<div>
			<Header routeName={routeName} />
			{isContentPage && (
				<div className="contentWrap">
					<div className="contentBody">
						<h1 className="title">{title}</h1>
						{children}
					</div>
				</div>
			)}
			{!isContentPage && (
				<div>
					{children}
				</div>
			)}
		</div>
	)
}

export default HeaderWrappedView