import './Testimonials.scss'

function Testimonials() {
  return (
    <div className="testimonialsWrapper">
      <div className="testimonialsContainer">
        <div className="testimonialsSubtitle">Take it from our customers...</div>
        <div className="testimonialsBody">
          <div className="testimonial">
            <div className="body">
              "Phat Purps Catering is the Best! Mando was very communicative throughout the entire process. He delivered on everything we discussed. Showed up on time, him and his staff were friendly and served 75 people with ease. I would definitely recommend Phat Purps to anyone!"
            </div>
            <div className="author">
              - Enrique M.
            </div>
          </div>
          <div className="testimonial">
            <div className="body">
              "Mando & his team at Phat Purps were an excellent catering service to my family party of 20 people. He was on time when he said he would arrive to start cooking and food was ready to be served at the time I requested. All the food was excellent to the taste and everyone complimented Mando on his food as well. There's a 100% chance of using Mando's catering services again for the next party."
            </div>
            <div className="author">
              - Jeremy S.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials