import logo from './../../Assets/logo.png'
import { Link } from 'react-router-dom'

import './Header.scss'

function Header({ routeName }) {
	return (
		<header className="wrapper">
			<div className="mobileLogoWrapper">
				<img src={logo} className="mobileLogo" alt="logo" />
			</div>
			<div className="navigation">
				<Link to="/" className={`navLink homeLink ${routeName === 'menu' ? 'navLinkActive' : ''}`}>Home</Link>
				<Link to="/menu" className={`navLink ${routeName === 'menu' ? 'navLinkActive' : ''}`}>Menu</Link>
				<Link to="/our-story" className={`navLink ${routeName === 'ourStory' ? 'navLinkActive' : ''}`}>Our Story</Link>			
				<Link to="/" className="navLink navLogo">
					<img src={logo} className="logo" alt="logo" />
				</Link>
				<Link to="/contact" className={`navLink navContact ${routeName === 'contact' ? 'navLinkActive' : ''}`}>Contact</Link>
			</div>
		</header>
	)
}

export default Header