import FeaturedImage from "../../Components/FeaturedImage/FeaturedImage"
import HeaderWrappedView from "./../HeaderWrappedView/HeaderWrappedView"
import Testimonials from "../../Components/Testimonials/Testimonials"

import './Home.scss'

function Home() {
	return (
		<HeaderWrappedView routeName="home">
			<FeaturedImage />
			<Testimonials />
		</HeaderWrappedView>
	)
}

export default Home